import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Box,
  Container,
  Paper,
  Dialog,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Logo from "./../../component/Logo";
import { FiSun } from "react-icons/fi";
import { FaRegMoon } from "react-icons/fa";
import SettingsContext from "src/context/SettingsContext";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 0,
    // background: theme.palette.background.blur,
    // border: "2px solid rgba(0, 0, 0, 0.025)",
    // backdropFilter: "blur(4px)",
    // borderRadius: 15,
    // padding: " 5px 0px",
    // marginTop: 20,
  },
  maindrawer: {
    height: "100%",
    background: "#0c0731",
    width: "260px",
  },
  logoDrawer: {
    // paddingLeft: "10px",
    width: "140px",
    // marginBottom: "30px",
  },
  drawerContainer: {
    padding: "20px 0px 20px 20px",
    height: "100%",
    background: theme.palette.background.header,
    color: "#fff",
    width: "260px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  drawericon: {
    color: "#000",
    // position: "absolute",
    // top: "0px",
    // right: "-18px",
    marginLeft: "0px !important",
    fontSize: "25px",
  },
  logoImg: {
    width: "75px",
    // height: '44.5px',
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "52px",
    },
  },
  paper1: {
    background: "black",
    color: "white",
  },

  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
  },

  menuMobile1: {
    // marginLeft: "10px",
    padding: "15px 0",
    "& h4": {
      fontSize: "14px !important",
      lineHeight: " 17px",
      color: theme.palette.text.main,
      margin: "0 8px",
      fontWeight: "400",
      [theme.breakpoints.only("xs")]: {
        fontSize: "12px !important",
      },
    },
    "& svg": {
      color: theme.palette.text.main,
      "@media (max-width:767px)": {
        display: "none",
      },
    },
    "&:hover": {
      // backgroundColor: theme.palette.background.card,
      backgroundColor: "transparent",
      // borderRadius: "40px",
    },
    "& figure": {
      margin: 0,
      width: 40,
      height: 40,
      borderRadius: "50px",
      overflow: "hidden",
      display: "flex",
      justifyContent: " center",
      alignItems: "center",
      "& img": {
        width: "auto",
        height: "auto",
        maxWidth: "100%",
        // maxHeight: "100%",
      },
    },
  },
  searchdiaogBox: {
    "& .MuiDialogContent-root": {
      minHeight: "calc(100vh - 100px)",
      [theme.breakpoints.only("xs")]: {
        padding: "20px 0 !important",
      },
    },
    "& .MuiDialog-paperScrollPaper": {
      overflowY: "auto",
    },
  },
}));

export default function Header() {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);

  const themeSeeting = useContext(SettingsContext);
  const changeTheme = (type) => {
    themeSeeting.saveSettings({
      theme: type,
    });
  };

  const {
    toolbar,
    drawerContainer,
    menuMobile1,

    drawericon,
    logoDrawer,
    mainHeader,
  } = useStyles();
  const history = useHistory();
  // eslint-disable-next-line
  const [updateMinSatkeOpen, setUpdateMinSatkeOpen] = useState(false); // eslint-disable-next-line
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1220
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const displayDesktop = () => {
    return (
      <Toolbar className={toolbar}>
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          {femmecubatorLogo}
        </Box>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <Box className="themeButton">
            {themeSeeting.settings.theme === "DARK" ? (
              <IconButton
                variant="contained"
                color="primary"
                onClick={() => {
                  changeTheme("LIGHT");
                }}
              >
                <FiSun />
              </IconButton>
            ) : (
              <IconButton
                variant="contained"
                color="primary"
                onClick={() => {
                  changeTheme("DARK");
                }}
              >
                <FaRegMoon />
              </IconButton>
            )}
          </Box>
          <Button
            variant="contained"
            className="ecoButton"
            to="/"
            color="primary"
            component={Link}
            style={{ marginLeft: "15px", whiteSpace: "pre" }}
          >
            Login
          </Button>
          &nbsp;
        </Box>
      </Toolbar>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));
    //mobile
    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            <img className={logoDrawer} src="images/logo.png" alt="" />

            <div>
              <IconButton
                aria-label="delete"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={() => setUpdateMinSatkeOpen(true)}
                className={menuMobile1}
                size="small"
              >
                <Button
                  variant="contained"
                  className="ecoButton"
                  to="/"
                  color="primary"
                  component={Link}
                  style={{ marginLeft: "15px", whiteSpace: "pre" }}
                >
                  Login
                </Button>
              </IconButton>
            </div>
          </div>
        </Drawer>

        <Box display="flex" justifyContent="space-between">
          {femmecubatorLogo}
        </Box>
        <Box display="flex" alignItems="center">
          <Box className="themeButton">
            {themeSeeting.settings.theme === "DARK" ? (
              <IconButton
                variant="contained"
                color="primary"
                onClick={() => {
                  changeTheme("LIGHT");
                }}
              >
                <FiSun />
              </IconButton>
            ) : (
              <IconButton
                variant="contained"
                color="primary"
                onClick={() => {
                  changeTheme("DARK");
                }}
              >
                <FaRegMoon />
              </IconButton>
            )}
          </Box>
          <IconButton
            className={drawericon}
            {...{
              edge: "start",
              color: "inherit",
              "aria-label": "menu",
              "aria-haspopup": "true",
              onClick: handleDrawerOpen,
            }}
          >
            <MenuIcon
              width="60px"
              height="60px"
              style={{ color: "#FF6600", fontSize: "26px" }}
            />
          </IconButton>
        </Box>
      </Toolbar>
    );
  };
  //mobile end

  const femmecubatorLogo = (
    <Box>
      <Link to="/">
        <Logo className="logoImg" />
      </Link>
    </Box>
  );

  return (
    <>
      <AppBar
        position={history.location.pathname !== "/" ? "relative" : "absolute"}
        elevation={0}
        style={{ backgroundColor: "transparent", border: "none" }}
      >
        <Container maxWidth="lg">
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>
      {dialogOpen && (
        <Paper>
          <Dialog
            fullWidth
            maxWidth="lg"
            className={classes.searchdiaogBox}
            style={{
              position: "absolute",
              top: "10%",
              // minHeight: "695px",
            }}
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
          >
            <IconButton
              className="closeButton"
              onClick={() => setDialogOpen(false)}
            >
              <CloseIcon style={{ color: "#AAAAAA" }} />
            </IconButton>

            <Box className="dialogBoxHeight">
              <CloseIcon style={{ color: "#AAAAAA" }} />
            </Box>
          </Dialog>
        </Paper>
      )}
    </>
  );
}
