import { Box } from "@material-ui/core";
import React from "react";
import CookieConsent from "react-cookie-consent";

const CookieConsentBanner = () => {
  return (
    <Box className="buttonAcc">
      <CookieConsent
        location="bottom"
        buttonText="Accept All Cookies"
        containerClasses="alert alert-warning col-lg-12"
        contentClasses="text-capitalize"
        cookieName="Arbitrage_Bot"
        style={{ zIndex: "9999" }}
        buttonStyle={{
          background: "linear-gradient(359.12deg, #FF6600 9.14%, #3333FF 110.76%)",
          boxShadow:
            "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
          borderRadius: "10px",
          color: "#fff",
          fontSize: "15px",
          lineHeight: " 20px",
          height: "40px",
          whiteSpace: "pre",
          minWidth: "100px",
        }}
      >
        By clicking "Accept All Cookies", you agree to the store the cookies on your device for enhancing site navigation, analyzing site usage, and assisting in our marketing efforts.
      </CookieConsent>
    </Box>
  );
};

export default CookieConsentBanner;
